<template>
    <div class="modal-nickname">
        <div class="head">
            <div class="title" v-html="'닉네임 변경'" />
            <div class="subtitle" v-html="'1회만 변경 가능하니 신중히 입력해주세요'" />
        </div>
        <div class="body">
            <div class="input-wrapper nickname flex-row">
                <input ref="nickname" placeholder="닉네임을 입력해주세요" v-model="nickname" @keydown="onKeydown" />
                <i v-if="nickname" class="material-icons" @click="nickname = ''">cancel</i>
            </div>
            <div class="ok" v-if="nicknameOk">
                <i class="material-icons c-primary">check_circle_outline</i>
                <span v-html="nicknameOk" />
            </div>
            <div class="error" v-if="error">
                <i class="material-icons c-error">error_outline</i>
                <span v-html="error" />
            </div>
        </div>
        <hr class="hor-divider" />
        <div class="buttons-basic">
            <div class="flex-row">
                <button @click="onClickClose()" class="btn-default" v-html="$translate('CANCEL')" />

                <button
                    @click="onClickChange()"
                    class="btn-primary"
                    :class="{ disabled: disabled }"
                    v-html="$translate('CHANGING')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user'
import debounce from '@/modules/debounce'

export default {
    name: 'ModalNickname',
    props: ['options'],
    data: () => ({
        nicknameOk: null,
        error: null,
        disabled: true,
        nickname: '',
    }),
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
        this.nickname = this.$store.getters.payloads.signupData.nickname
        this.$refs.nickname.focus()
        this.checkNickname()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
        buttons() {
            return [
                {
                    label: 'CANCEL',
                    class: 'btn-default',
                },
                {
                    label: 'CHANGING',
                    class: 'btn-primary',
                },
            ]
        },
    },
    methods: {
        onKeydown: debounce(function () {
            this.nickname = this.$refs.nickname.value
            this.checkNickname()
        }, 200),
        onClickClose() {
            this.$emit('close')
        },
        async onClickChange() {
            if (this.error || this.disabled) return

            const me = this.$store.getters.me
            const payload = { nickname: this.nickname }
            await userService.updateNickname(me.id, payload)
            // 서버 닉넴 변경 호출p
            this.$emit('close', this.nickname)
        },
        async checkNickname() {
            if (!this.nickname) {
                this.error = null
                this.nicknameOk = null
                this.disabled = true
                return
            }

            try {
                const temp = this.nickname
                const res = await userService.checkNickname(temp)
                this.error = res.result ? null : res.msg
                this.nicknameOk = res.result ? '사용 가능한 닉네임이에요!' : null
                this.disabled = !this.nicknameOk

                if (res.result) {
                    this.$store.commit('setPayloads', {
                        signupData: {
                            nickname: temp,
                        },
                    })
                    if (this.fbPayload) {
                        this.fbPayload.nickname = this.nickname
                    }
                }
            } catch (e) {}
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-nickname {
    padding: 0px !important;
    margin: 0px;
    text-align: center;
    .title {
        font-size: 18px;
        text-align: center;
        @include f-medium;
        height: 27px;
        // padding: 4px;
        margin: 20px 32px 0px 32px;
        color: #000000;
    }
    .subtitle {
        margin-top: 4px;
        font-size: 12px;
        @include f-medium;
        color: $grey-06;
        text-align: center;
    }
    .body {
        // display: inline-flex;
        font-size: 14px;
        margin: 20px 0px 20px 0px;
        // height: 22px;
        color: #000000;
        @include f-regular;

        .nickname {
            height: 48px;
            margin-left: 16px;
            margin-right: 16px;
            // padding: 14px 16px 14px 16px;
            padding: 0px 16px 0px 16px;
            font-size: 14px;

            i {
                align-self: center;
            }
        }

        .btn {
            padding: 0px;
        }

        .ok {
            font-size: 13px;
            color: $purple-primary;
            margin-top: 10px;
            margin-left: 16px;
            text-align: left;
            .c-primary {
                margin-right: 4px;
                margin-top: 2px;
                color: $purple-primary;
                font-size: 16px;
            }
        }

        .error {
            font-size: 13px;
            color: $pink-deep;
            margin-top: 10px;
            margin-left: 16px;
            text-align: left;
            .c-error {
                margin-right: 4px;
                margin-top: 2px;
                color: $pink-deep;
                font-size: 16px;
            }
        }
    }

    .hor-divider {
        border-bottom: 1px solid rgba(241, 242, 244, 1);
    }

    .btn-primary {
        height: 48px;
        width: 100%;
        background-color: white;
        color: $purple-primary;
        box-shadow: none;
        font-size: 16px;
        @include f-medium;
    }

    .buttons-basic {
        width: 100%;
        .flex-row {
            .btn-default {
                margin-left: 8px;
            }
            .btn-primary {
                background-color: white;
                color: $purple-primary;
                box-shadow: none;
                margin-right: 8px;

                &.disabled {
                    // background: $grey-02;
                    box-shadow: none;
                    color: $grey-05;
                    @include f-regular;
                }
            }

            & > * {
                font-size: 16px;
                font-weight: normal;
                border: none;
                width: 50%;
                padding: 16px 16px;
            }
        }
    }

    input {
        border: none;
        box-shadow: none;
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
}
</style>
